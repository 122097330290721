import styled from 'styled-components';
import { Container } from '@components/layout/Grid';
import hero from '@ecosystems/landing_pages/hero/Hero';
import { Button as button } from '@components/ButtonV2';
import {
  Title,
  VideoWrapper,
  TextWrapper,
  ActionsWrapper,
} from '@ecosystems/landing_pages/hero/components';
import { IOS_APP_LINK, ANDROID_APP_LINK } from '@lib/constants';
import { MobileAppSection as MobileAppSection_T } from '@gql/generated';
import { Trans } from '@lingui/react';

const Section = styled(Container)`
  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    background-image: url('/static/start_page/app-illustration.svg');
    background-size: 50% 70%;
    background-position: 140% center;
    background-repeat: no-repeat;
  }
`;

const Hero = styled(hero)`
  ${VideoWrapper} {
    img {
      max-height: 600px;
      width: 100%;
      object-fit: contain;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  ${TextWrapper} {
    text-align: center;
    padding-right: 0;
    z-index: 1;
  }

  ${ActionsWrapper} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    margin-top: 40px;

    ${TextWrapper} {
      text-align: left;
    }

    ${ActionsWrapper} {
      justify-content: flex-start;
    }
  }
`;

const Button = styled(button)`
  flex: 1;
  max-width: min(200px, 100%);
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 24px;
    object-fit: contain;
    margin-right: 10px;
  }
`;

type Props = { id: string; data: MobileAppSection_T };
export const MobileAppSection = (props: Props) => {
  const {
    id,
    data: { title, description, imageUrl },
  } = props;

  return (
    <Section id={id} fullWidth>
      <Container
        flexDirection="column"
        alignItems="center"
        px={[20, 20, 20]}
        mb={35}
      >
        <Hero
          titleAs="h2"
          image={imageUrl}
          title={title}
          description={description}
          actions={[
            <Button
              href={IOS_APP_LINK}
              key="0"
              target="_blank"
              appearance="dark"
              rounded
            >
              <img src="/static/icons/1200px-Apple_logo_white.svg-p-500.png" />
              <Trans id="homepage.mobile_section.app_store_btn" />
            </Button>,
            <Button
              href={ANDROID_APP_LINK}
              key="1"
              target="_blank"
              appearance="dark"
              rounded
            >
              <img src="/static/icons/Google_Play_Arrow_logo.svg-p-500.png" />
              <Trans id="homepage.mobile_section.play_store_btn" />
            </Button>,
          ]}
        />
      </Container>
    </Section>
  );
};
